import { render, staticRenderFns } from "./NumberInput.vue?vue&type=template&id=d71f6d30"
import script from "./NumberInput.vue?vue&type=script&lang=js"
export * from "./NumberInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,TextField: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TextField/TextField.vue').default})
