
export default {
  props: {
    value: {
      type: String,
    },

    validateInput: {
      type: Boolean,
      default: false,
    },

    labelInput: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    characterCountLimit: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      validate: {
        number(value) {
          return !isNaN(value);
        },
      },
    };
  },

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    onValid(valid) {
      valid ? this.$emit("validate-success") : this.$emit("validate-error");
    },
  },
};
